$icon-size: (8,10,12,13,14,16,18,20,22,24,25,26,28,30,32, 34,36,40,42,45,48,50,56,60,64,72,76,80,90,100,115, 150, 200, 250);

$icons: (
  bell,
  x, alert,
  required-check, info, qusition,
  arrow-left, arrow-right, arrow-down,
  footer-home,footer-history,footer-setting, home,
  eye, on-eye,
  check, camera, photo, clip, calendar,
  setting
);

$banks: (
  1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31
);

$stoks: (
  1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30
);


i.icon {
    min-width: 24px;
    min-height: 24px;
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 2;

    @each $var in $icons {
        &.icon-#{$var} {
            -webkit-mask: url(../../assets/icons/#{$var}.svg) no-repeat 50% 50%;
            mask: url(../../assets/icons/#{$var}.svg) no-repeat 50% 50%;
            mask-size: contain;
            background-color: var(--font);
        }

        &.icon-#{$var}-o {
            background-image: url(../../assets/icons/#{$var}.svg);
        }
    }

    @each $var in $icon-size {
        &.icon-#{$var} {
            min-width: #{$var}px !important;
            width: #{$var}px;
            height: #{$var}px;
            mask-size: #{$var}px #{$var}px;
            background-size: #{$var}px #{$var}px;
        }
    }
}

@each $index, $bank in $banks {
    .icon-bank-#{$index} {
        background-image: url(../../assets/images/bank/#{$index}.svg);
    }
}

@each $index, $stok in $stoks {
    .icon-stock-#{$index} {
        background-image: url(../../assets/images/bank/stoks/#{$index}.svg);
    }
}


.icon-circle-bg {background-color: var(--font-variant); border: 1px solid var(--font);}