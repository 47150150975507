.actionSheet {
    z-index: 10001;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-radius: 2rem 2rem 0 0;
    background-color: var(--white);
}

.header {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid var(--disabled);
    padding: 1.6rem;
    padding-bottom: 1.4rem;
}

.items {
    padding: 2.4rem;
}

.items div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button {
    color: var(--blue);
    border: 1px solid var(--blue);
    border-radius: .6rem;
    padding: .6rem 1.4rem;
    background-color: transparent;
    font-size: 1.3rem;
    font-weight: 600;
}

.underline {
    margin: 1.8rem 0 !important;
}

.underline:last-child {
    display: none !important;
}