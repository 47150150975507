@import url(../scss/utils/fonts.scss);
@import url(../scss/utils/colors.scss);
@import url(../scss/utils/icons.scss);
@import url(../scss/utils/mp.scss);
@import url(../scss/app/Splash.scss);

$bottom-height: 48px;

:root {
    --border-radius-sm: 8px;
    --border-radius-md: 12px;
}

body {margin: 0;}
img {width: 100%; height: auto;}
section {max-width: 560px; margin: 0 auto; background-color: var(--white);}
section > div:first-child {width: calc(100% - 2.8rem); padding: 1.6rem; padding-top: 2.4rem; padding-bottom: 4.8rem; overflow: auto;}
.page {width: calc(100% - 2.8rem); padding: 1.6rem; padding-top: 2.4rem; padding-bottom: 4.8rem; overflow: auto;}
input:focus, textarea:focus {outline: none;}
.text-center {text-align: center};
.flex-center {display: flex; align-items: center; justify-content: space-between;}

.dblock {display: block;}
.w100 {width: 100% !important;}

.tab {transition: all .3s ease; -webkit-tap-highlight-color: transparent;}
.tab:active {transform: scale(.85);}
i {transition: all .3s ease; -webkit-tap-highlight-color: transparent;}
i:active {transform: scale(.85);}



.underline {width: 100%; background-color: var(--disabled); height: 1px; margin-top: 1.6rem; margin-bottom: 2.4rem;}
.divider {background-color: var(--background); margin: 0; height: 1.4rem;}
// Toast.svelte ----------------------------------------------------------------
.toastAlert {position: fixed; bottom: 4.8rem; z-index: 10000; background-color: var(--black); color: var(--white); padding: 1.6rem; margin: 0 1.4rem; width: calc(100% - 6rem); border-radius: var(--border-radius-sm); font-size: 13px; text-align: center;}
// Toast.svelte ----------------------------------------------------------------

.bottom {bottom: 3.6rem; position: absolute; width: calc(100% - 3.2rem) !important; max-width: calc(560px - 2.8rem);}

.background { z-index: 10000; position: fixed; top: 0; left: 0; width: 100%; height: 100vh; background-color: #11111170; }

.input { border: 1px solid #E5E5EC; font-size: 1.4rem; padding: 1.4rem 1.6rem; width: calc(100% - 3.8rem); border-radius: 6px; }
.input:read-only {filter: brightness(.85); opacity: .5;}
.ssn-input {display: grid !important; width: calc(100% - 1.6rem); grid-template-columns: 48% 4% 48%; gap: .8rem; align-items: center; justify-content: space-between;}
.phone-input {display: grid !important; grid-template-columns: 1fr auto 1fr auto 1fr; gap: .8rem; align-items: center; justify-content: space-between;}
.input-required {border: 1px solid var(--red);}
.char-count { text-align: right; padding: 1.8rem; position: relative; margin-top: -4.8rem;}
label {font-size: 1.4rem; color: var(--black);}

canvas {border: 2px solid var(--lightGray); border-radius: .4rem; height: 116px; width: 100%;}
