$values: (0, 2, 4, 6, 8, 10, 12, 16, 18, 20, 24, 28, 32, 36,44, 48,56, 60, 72, 90, 96, 120);
  
  @each $var in $values {
    .pd#{$var} { padding: #{$var}px; }
    .pl#{$var} { padding-left: #{$var}px; }
    .pr#{$var} { padding-right: #{$var}px; }
    .pt#{$var} { padding-top: #{$var}px; }
    .pb#{$var} { padding-bottom: #{$var}px; }
    .py#{$var} { padding-top: #{$var}px; padding-bottom: #{$var}px; }
    .px#{$var} { padding-left: #{$var}px; padding-right: #{$var}px; }
  
    // @media (max-width: 789px) {
    //   .pd#{$var} { padding: #{$var * 0.6}px; }
    //   .pl#{$var} { padding-left: #{$var * 0.6}px; }
    //   .pr#{$var} { padding-right: #{$var * 0.6}px; }
    //   .pt#{$var} { padding-top: #{$var * 0.6}px; }
    //   .pb#{$var} { padding-bottom: #{$var * 0.6}px; }
    //   .py#{$var} { padding-top: #{$var * 0.6}px; padding-bottom: #{$var * 0.6}px; }
    //   .px#{$var} { padding-left: #{$var * 0.6}px; padding-right: #{$var * 0.6}px; }
    // }
  }
  
  @each $var in $values {
    .mg#{$var} { margin: #{$var}px; }
    .ml#{$var} { margin-left: #{$var}px; }
    .mr#{$var} { margin-right: #{$var}px; }
    .mt#{$var} { margin-top: #{$var}px; }
    .mb#{$var} { margin-bottom: #{$var}px; }
    .my#{$var} { margin-top: #{$var}px; margin-bottom: #{$var}px; }
    .mx#{$var} { margin-left: #{$var}px; margin-right: #{$var}px; }
  
    // @media (max-width: 789px) {
    //   .mg#{$var} { margin: #{$var * 0.6}px; }
    //   .ml#{$var} { margin-left: #{$var * 0.6}px; }
    //   .mr#{$var} { margin-right: #{$var * 0.6}px; }
    //   .mt#{$var} { margin-top: #{$var * 0.6}px; }
    //   .mb#{$var} { margin-bottom: #{$var * 0.6}px; }
    //   .my#{$var} { margin-top: #{$var * 0.6}px; margin-bottom: #{$var * 0.6}px; }
    //   .mx#{$var} { margin-left: #{$var * 0.6}px; margin-right: #{$var * 0.6}px; }
    // }
  }