.alert {
    z-index: 10001;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white);
    border-radius: var(--border-radius-sm);
    padding-top: 1.6rem;
    width: 90%;
    max-width: 50rem;
    text-align: center;
}

.button {
    width: 100%;
    background-color: var(--black);
    color: var(--white);
    border: none;
    padding: 1.6rem;
    border-radius: 0 0 var(--border-radius-sm) var(--border-radius-sm)
}

.icon {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
}