header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    margin: 0 auto;
    padding: 1.6rem;
    z-index: 1000;
    border-bottom: 1px solid var(--lightGray);
}

header div {
    display: flex;
    align-items: center;
    gap: .8rem;
}

header img {
    width: 150px;
}