html {
    overflow: hidden;
    overscroll-behavior: none;
}

body {
    touch-action: none;
    overscroll-behavior: none;
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.signature_img {
    border: 1px solid #E5E5EC;
    margin-top: 0.8rem;
    border-radius: 6px;
}

.MuiDayCalendar-weekDayLabel {
    font-size: 1.5rem !important;
}

.MuiPickersDay-root {
    font-size: 1.5rem !important;
}

.MuiPickersCalendarHeader-labelContainer {
    font-size: 1.5rem !important;
}

.MuiPickersCalendarHeader-switchViewIcon {
    width: 1.5em !important;
    height: 1.5em !important;
}

.MuiSvgIcon-root {
    width: 1.5em !important;
    height: 1.5em !important;
}

.MuiPickersYear-yearButton {
    font-size: 1.5em !important;
    line-height: 0.5 !important;
}
