#홈 {
    .item {padding: 2rem; border-radius: var(--border-radius-md); background-color: var(--white); box-shadow: 0px 0px 6px 0px #00000004, 0px 0px 1px 0px #00000050;}

    .lpGroup {display: grid; grid-template-columns: 50% 50%; width: calc(100% - 1.2rem); gap: 1.2rem;}
    .lpGroup .item {display: flex; align-items: start; padding: 1.4rem 1.6rem; height: 110px; position: relative;}
    .lpGroup .item img {width: 32px; margin-left: auto;}
    .lpGroup .item .title {display: flex; flex-direction: column;}
    p {font-size: 1.5rem; line-height: unset;}

    .info {display: flex; justify-content: space-between; align-items: center;}
    .info > div:first-child {display: flex; align-items: center; gap: .8rem;}

    article {margin-bottom: 1.2rem;}
    .view-details {position: absolute; bottom: 15px; right: 15px; padding: 5px; border: 1px solid #E6E6E6; background-color: #fff; color:'#111111'; font-weight: 600; font-size: 11px;}
}